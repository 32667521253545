import { template as template_18c19f3bf3af4aa888c8e67c7a3fd2fe } from "@ember/template-compiler";
const FKControlMenuContainer = template_18c19f3bf3af4aa888c8e67c7a3fd2fe(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
