import { template as template_388002b3ae32493cabca755c3efcb0f7 } from "@ember/template-compiler";
const FKLabel = template_388002b3ae32493cabca755c3efcb0f7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
