import { template as template_5e71c3b255eb41ca88b888404bb06f8b } from "@ember/template-compiler";
const FKText = template_5e71c3b255eb41ca88b888404bb06f8b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
