import { template as template_587c1f480e1246bcb65f4265cd357833 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_587c1f480e1246bcb65f4265cd357833(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
